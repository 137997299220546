import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import { Controller, useForm, useWatch } from 'react-hook-form';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  styled,
  TextField,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { RhfFileSelector } from '@/components/FileUpload/FileSelector';
import { Offer, OfferEnableFbcResponse } from '@/types/unity';
import api from '@/utils/api';

const OfferFormContainer = styled(Box)(
  ({ theme }) => `
    padding: ${theme.spacing(2)} 0;
    border-bottom: solid 1px ${theme.palette.divider};

    &:first-of-type {
      padding-top: 0;
    }
    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }
`,
);

interface FormValues {
  [offerId: string]: {
    offerExternalId: string;
    fbcSku: string;
    isDangerousGoods: 'true' | 'false' | null;
    dangerousGoodsSds?: FileWithPath;
  };
}

interface SubmitFeedback {
  [externalOfferId: string]: {
    success: boolean;
    message: string;
  };
}

export interface OfferEnableFbcModalProps {
  offers: Offer[];
  onClose: (result?: OfferEnableFbcResponse) => void;
}

export default function OfferEnableFbcModal({
  offers,
  onClose,
}: OfferEnableFbcModalProps) {
  const theme = useTheme();

  const values: FormValues = {};
  for (const offer of offers) {
    values[`${offer.id}`] = {
      offerExternalId: offer.externalId,
      fbcSku: offer.fbcSku,
      isDangerousGoods: null,
    };
  }

  const [formSubmitting, setFormSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [submitFeedback, setSubmitFeedback] = useState<SubmitFeedback>({});
  const { control, handleSubmit, getValues } = useForm<FormValues>({
    mode: 'onTouched',
    values,
  });

  useWatch({
    control,
    name: Object.keys(values).map((offerId) => `${offerId}.isDangerousGoods`),
    disabled: offers.length <= 0,
  });

  const handleClose = useCallback(
    (result?: OfferEnableFbcResponse) => {
      setSubmitFeedback({});
      setError('');
      setFormSubmitting(false);
      onClose(result);
    },
    [onClose],
  );

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      setFormSubmitting(true);
      setError('');

      const formData = new FormData();
      for (const index in formValues) {
        const value = formValues[index];
        formData.set(`offers[${index}][offerId]`, value.offerExternalId);
        formData.set(`offers[${index}][fbcSkuId]`, value.fbcSku);
        formData.set(
          `offers[${index}][isDangerousGoods]`,
          value.isDangerousGoods!,
        );
        if (value.isDangerousGoods === 'true' && value.dangerousGoodsSds) {
          formData.set(
            `offers[${index}][dangerousGoodsSds]`,
            value.dangerousGoodsSds,
            value.dangerousGoodsSds.name,
          );
        }
      }

      let hasError = false;
      let responsePayload: OfferEnableFbcResponse | null = null;

      try {
        const response = await api.post<OfferEnableFbcResponse>(
          '/unity/mirakl/offers/enable-fbc',
          formData,
        );
        responsePayload = response.data;

        const submitFeedback: SubmitFeedback = {};
        for (const result of responsePayload) {
          if (result.error) {
            hasError = true;
            submitFeedback[result.offerId] = {
              success: false,
              message: result.error,
            };
          } else {
            submitFeedback[result.offerId] = {
              success: true,
              message: 'Offer is being set up for FBC',
            };
          }
        }
        setSubmitFeedback(submitFeedback);
      } catch (e: any) {
        hasError = true;
        let errorMessage =
          e.message ?? 'An error occurred, please try again later';
        if (e instanceof AxiosError && e.response?.data?.message) {
          errorMessage = e.response.data.message;
        }
        setError(errorMessage);
      }

      setFormSubmitting(false);

      if (!hasError && responsePayload !== null) {
        handleClose(responsePayload);
      }
    },
    [handleClose],
  );

  return (
    <Modal open={offers.length > 0} onClose={() => handleClose()}>
      <Container
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        disableGutters
        maxWidth="md"
      >
        <Card>
          <CardHeader
            id="offer-enable-fbc-modal-title"
            title={`Enable ${offers.length > 1 ? 'Offers' : 'Offer'} for FBC`}
            action={
              <IconButton onClick={() => handleClose()}>
                <CloseTwoToneIcon fontSize="small" />
              </IconButton>
            }
            sx={{ padding: theme.spacing(2, 3) }}
          />
          <Divider />
          <CardContent
            sx={{ paddingX: 3, maxHeight: '80vh', overflowY: 'auto' }}
          >
            <>
              {!!error && (
                <Alert severity="error" sx={{ mb: 1 }}>
                  {error}
                </Alert>
              )}
              <form onSubmit={handleSubmit(onSubmit)}>
                {offers.map((offer) => (
                  <OfferFormContainer key={offer.id}>
                    {!!submitFeedback[offer.externalId] && (
                      <Alert
                        severity={
                          submitFeedback[offer.externalId].success
                            ? 'success'
                            : 'error'
                        }
                        sx={{ mb: 1 }}
                      >
                        {submitFeedback[offer.externalId].message}
                      </Alert>
                    )}
                    <Stack
                      sx={{ flexDirection: 'row', columnGap: theme.spacing(4) }}
                    >
                      <Box sx={{ fontWeight: 'bold' }}>
                        {offer.skuName} ({offer.externalOfferSku})
                      </Box>
                      <Box>Price: {offer.price}</Box>
                      <Box>Quantity: {offer.quantity}</Box>
                    </Stack>
                    <Stack
                      sx={{
                        flexDirection: 'row',
                        columnGap: 4,
                        pt: 2,
                      }}
                    >
                      <Controller
                        name={`${offer.id}.fbcSku`}
                        control={control}
                        disabled={formSubmitting}
                        render={({ field, fieldState }) => (
                          <Box>
                            <TextField
                              label="FBC SKU ID"
                              {...field}
                              error={fieldState.invalid}
                            />
                            {fieldState.invalid && (
                              <FormHelperText error sx={{ my: 1 }}>
                                {fieldState.error?.message || (
                                  <span>
                                    Max length: 19 characters
                                    <br />
                                    Allowed characters: A-Z, 0-9, -, _
                                  </span>
                                )}
                              </FormHelperText>
                            )}
                          </Box>
                        )}
                        rules={{
                          required: 'This field is required',
                          pattern: /^[A-Za-z0-9_-]{1,19}$/,
                        }}
                      />
                      <Controller
                        name={`${offer.id}.isDangerousGoods`}
                        control={control}
                        disabled={formSubmitting}
                        render={({ field, fieldState }) => (
                          <FormControl disabled={formSubmitting}>
                            <FormLabel
                              id={`offer_${offer.id}_isDangerousGoods`}
                              error={fieldState.invalid}
                            >
                              Is dangerous goods?
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby={`offer_${offer.id}_isDangerousGoods`}
                              {...field}
                            >
                              <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                            {fieldState.error?.message && (
                              <FormHelperText error sx={{ m: 0 }}>
                                {fieldState.error?.message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                        rules={{
                          required: 'This field is required',
                        }}
                      />
                    </Stack>
                    {getValues(`${offer.id}.isDangerousGoods`) === 'true' && (
                      <Box>
                        <RhfFileSelector
                          name={`${offer.id}.dangerousGoodsSds`}
                          control={control}
                          disabled={formSubmitting}
                          accept={{ 'application/pdf': ['.pdf'] }}
                          rules={{
                            required: 'This field is required',
                          }}
                          prompt="Drag and drop dangerous goods SDS here, or click to select a file"
                        />
                      </Box>
                    )}
                  </OfferFormContainer>
                ))}
                <LoadingButton
                  type="submit"
                  variant="contained"
                  disabled={formSubmitting}
                  loading={formSubmitting}
                  sx={{ mt: 2 }}
                >
                  Submit
                </LoadingButton>
              </form>
            </>
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
}
