import { Card, CardActionArea, CardContent, styled } from '@mui/material';
import { useMemo } from 'react';
import { useDropzone, FileRejection, DropEvent, Accept } from 'react-dropzone';

export type OnDropProps = <T extends File>(
  acceptedFiles: T[],
  fileRejections: FileRejection[],
  event: DropEvent,
) => void;

export interface DropzoneProps {
  onDrop?: OnDropProps;
  accept?: Accept;
  error?: boolean;
  prompt?: string;
  disabled?: boolean;
}

const DragDropCard = styled(Card)(
  ({ theme }) => `
        border: ${theme.colors.primary.main} dashed 1px;
        height: 100%;
        color: ${theme.colors.primary.main};
        box-shadow: none;

        &.focused {
          border-color: ${theme.colors.primary.light};
        }

        &.accepted {
          border-color: ${theme.colors.success.main};
        }

        &.rejected {
          border-color: ${theme.colors.error.main};
          color: ${theme.colors.error.main};
        }

        &.disabled {
          border-color: ${theme.colors.alpha.black[50]};
          color: ${theme.colors.alpha.black[30]};
          background-color: ${theme.colors.alpha.black[5]};
          pointer-events: none;
        }

        .MuiCardActionArea-root {
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
        }

        .MuiTouchRipple-root {
          opacity: .2;
        }

        &:hover:not(.disabled) {
          color: ${theme.colors.primary.main};
        }
`,
);

export default function Dropzone({
  onDrop,
  accept,
  error,
  prompt,
  disabled,
}: DropzoneProps) {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragActive,
    isDragReject,
  } = useDropzone({
    accept,
    onDrop,
    disabled,
  });

  const classNamesConfig = {
    dropzone: true,
    focused: isFocused,
    accepted: isDragAccept,
    rejected: isDragReject || error,
    disabled: disabled,
  };
  const classNames = Object.keys(classNamesConfig).filter(
    (cls) => !!(classNamesConfig as any)[cls],
  );

  return (
    <DragDropCard className={classNames.join(' ')} {...getRootProps()}>
      <CardActionArea sx={{ px: 1 }}>
        <CardContent>
          <input className="input-zone" {...getInputProps()} />
          <div className="text-center">
            {isDragActive && !isDragReject ? (
              <p className="dropzone-content">Release to drop the file here</p>
            ) : isDragActive && isDragReject ? (
              <p className="dropzone-content">Invalid file type</p>
            ) : (
              <p className="dropzone-content">
                {prompt ?? 'Drag and drop CSV here, or click to select a file'}
              </p>
            )}
          </div>
        </CardContent>
      </CardActionArea>
    </DragDropCard>
  );
}
