import { useCallback, useState } from 'react';
import PageTitleWrapper from '@/components/PageTitleWrapper';
import {
  Alert,
  AlertColor,
  Container,
  Grid,
  Snackbar,
  SnackbarCloseReason,
  Typography,
} from '@mui/material';
import Footer from '@/components/Footer';
import { Offer } from '@/types/unity';
import OfferTable from './OfferTable';
import OfferEnableFbcModal from './OfferEnableFbcModal';

export default function OfferPage() {
  const [selectedOffers, setSelectedOffers] = useState<Offer[]>([]);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastSeverity, setToastSeverity] = useState<AlertColor>('info');
  const [toastContent, setToastContent] = useState('');

  const handleCloseToast = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
      if (reason === 'clickaway') {
        return;
      }

      setToastOpen(false);
    },
    [],
  );

  return (
    <>
      <title>Mirakl Offers</title>
      <PageTitleWrapper>
        <Typography variant="h3" component="h3" gutterBottom>
          Mirakl Offers
        </Typography>
        <Typography variant="subtitle2">
          A list of your Mirakl offers.
        </Typography>
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <OfferTable onOffersSelectedForFbc={setSelectedOffers} />
          </Grid>
        </Grid>
      </Container>
      <Footer />

      <OfferEnableFbcModal
        offers={selectedOffers}
        onClose={(result) => {
          setSelectedOffers([]);

          if (result) {
            let severity: AlertColor = 'success';
            let message = '';
            if (result.every((entry) => !entry.error)) {
              severity = 'success';
              message = `${result.length > 1 ? 'Offers are' : 'Offer is'} being set up for FBC`;
            } else if (result.every((entry) => !!entry.error)) {
              severity = 'error';
              message = `Failed to enable ${result.length > 1 ? 'offers' : 'offer'} for FBC`;
            } else {
              severity = 'warning';
              message = 'Some offers have failed to be set up for FBC';
            }
            setToastSeverity(severity);
            setToastContent(message);
            setToastOpen(true);
          }
        }}
      />

      <Snackbar
        open={toastOpen}
        autoHideDuration={6000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseToast}
          severity={toastSeverity}
          variant="standard"
          sx={{ width: '100%' }}
        >
          {toastContent}
        </Alert>
      </Snackbar>
    </>
  );
}
